import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

import {
  version
} from '../../package.json'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    plutoLots: [],
    VERSION: version,
    zoningData: {}
  },
  getters: {
    getPlutoLots (state) {
      return state.plutoLots
    },
    getVersion (state) {
      return state.VERSION
    },
    getZoningData (state) {
      return state.zoningData
    }
  },
  mutations: {
    setPlutoLots (state, newPlutoLots) {
      state.plutoLots = newPlutoLots
    },
    setZoningData (state, zoningData) {
      state.zoningData = zoningData
    },
    setZoningDataDistrict (state, districtInfo) {
      state.zoningData[districtInfo[0]] = districtInfo[1]
    }
  },
  actions: {
    readS3ImageFile (context, path) {
      return new Promise((resolve, reject) => {
        axios.post('https://optimus.kpfui.dev/api/zoning/getS3ImageFile', {
          'Bucket': 'kpfui-zoning-bucket',
          'Key': path
        }).then((res) => {
          resolve(res.data)
        })
      })
    },
    async readDistrictData (context, district) {
      try {
        const response = await axios.post('https://optimus.kpfui.dev/api/zoning/getDistrictData', {
          'district': district
        })

        const districtInfo = {}

        const data = response.data.data

        const headers = Object.keys(data).filter((e) => e !== '_id' && e !== 'district')

        headers.forEach((header) => {
          districtInfo[header] = {
            title: header,
            key: 0
          }

          districtInfo[header]['categories'] = Object.keys(data[header]).map(category => {
            const d = data[header][category]
            return {
              header: category,
              description: d.description,
              link: d.link,
              diagram: d.diagram === undefined ? null : d.diagram
            }
          })
        })

        return districtInfo
      } catch (error) {
        return null
      }
    },
    async readDecisionTree (context, payload) {
      const response = await axios.post('https://megatron.kpfui.dev/zoning/readDecisionTree', payload)

      return response.data.result
    },
    readPlutoData (context, payload) {
      const [lng, lat, distance] = payload

      const parameters = {
        'coords': [lng, lat],
        'distance': distance
      }

      axios.post('https://optimus.kpfui.dev/api/zoning/getPlutoDataByPoint', parameters).then(function (res) {
        const parsedResponse = JSON.parse(res['data']['data'])

        const geojsonStructure = {
          type: 'FeatureCollection',
          features: []
        }

        parsedResponse.forEach((d) => {
          geojsonStructure.features.push({
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: d['geometry']['rings']
            },
            properties: d['attributes']
          })
        })

        context.commit('setPlutoLots', geojsonStructure)
      })
    }
  }
})
