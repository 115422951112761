<template>
  <div>
    <img :src="diagram" alt="alt-image">
  </div>
</template>

<script>

import axios from 'axios'

export default {
  name: 'districtDiagram',
  props: {
    src: String
  },
  data () {
    return {
      diagram: ''
    }
  },
  created () {
    this.getDiagram(this.src)
  },
  methods: {
    async getDiagram (path) {
      const img = await axios.post('https://optimus.kpfui.dev/api/zoning/getS3ImageFile', {
        'Bucket': 'kpfui-zoning-bucket',
        'Key': path
      })

      this.diagram = img.data
    }
  }
}
</script>

<style lang="scss" scoped>

img{
  width: 100%;
  height: auto;
}

</style>
