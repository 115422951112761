<template>
  <div id="decisionForm" ref="decisionForm" class="districtForm">
    <R10Form v-if="district === 'R10'" class="form-padding" :plutoData="plutoData" />
  </div>
</template>

<script>
import R10Form from '@/components/R10_form.vue'

export default {
  name: 'decisionForm',
  props: {
    district: String,
    plutoData: Object
  },
  components: {
    R10Form: R10Form
  }
}
</script>

<style lang="scss">
.form-padding {
  padding: 1rem 0 4rem 0;
}
</style>
