<template>
  <div id="Map">
    <div id="map-container">
      <MglMap
        :accessToken="accessToken"
        :mapStyle.sync="mapStyle"
        container="map-parent"
        @load="onMapLoaded"
        :zoom="zoom"
        :center="center"
      >
        <MglNavigationControl position="top-right" />
        <MglGeolocateControl position="top-right" />
      </MglMap>
    </div>
    <Sidebar @updateFlyTo="flyTo" />
  </div>
</template>

<script>
// IMPORT MAPBOX FUNCTIONS AND TEMPLATES
import Mapbox from 'mapbox-gl'

import { MglMap, MglNavigationControl, MglGeolocateControl } from 'vue-mapbox'

import { mapGetters } from 'vuex'

import Sidebar from '@/components/Sidebar.vue'

import { createPlutoZoningObject } from '@/assets/library/helpers.js'

let map = null
let featureID = null
let popup = null
let marker = null

export default {
  name: 'Map',
  data () {
    return {
      // MAPBOX LIGHT STYLE TOKEN AND URL WITH BASIC SETTINGS
      accessToken:
        'pk.eyJ1Ijoia3BmdWkiLCJhIjoiY2p6MWcxMXl4MDFlbTNsbDc1bnp6N3FjYSJ9.66qFOXwI661MOPOf7x96yA',
      mapStyle: 'mapbox://styles/mapbox/light-v10',
      zoom: 11,
      center: [-73.96, 40.76],
      zoningData: {},
      selectedLngLat: []
    }
  },
  components: {
    MglMap,
    MglNavigationControl,
    MglGeolocateControl,
    Sidebar
  },
  created () {
    // ATTACHES MAP TO INSTANCE
    this.mapbox = Mapbox

    popup = new this.mapbox.Popup({
      closeButton: false,
      closeOnClick: false
    })
  },
  mounted () {
    this.plutoLotSubscriber()
  },
  computed: {
    ...mapGetters({
      plutoLots: 'getPlutoLots'
    })
  },
  methods: {
    flyTo (data) {
      const [lng, lat, zoom] = data
      this.selectedLngLat = [lng, lat]
      map.flyTo({
        center: [lng, lat],
        zoom: zoom,
        speed: 3,
        curve: 1,
        easing (t) {
          return t
        }
      })
    },
    addPlutoSource () {
      map.addSource('pluto-source-data', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: { name: 'Null Island' },
              geometry: {
                type: 'Point',
                coordinates: [0, 0]
              }
            }
          ]
        },
        generateId: true
      })
    },
    // FIRES ON MAP LOAD
    onMapLoaded (event) {
      map = event.map

      this.addPlutoSource()
    },
    addPlutoLots (plutoLots) {
      // try and get the souce
      if (map.isSourceLoaded('pluto-source-data')) {
        map.getSource('pluto-source-data').setData(plutoLots)
        this.testHighlightLotSource()
      } else {
        this.addPlutoSource()
      }

      if (map.getLayer('pluto-lots') === undefined) {
        map.addLayer({
          id: 'pluto-lots',
          source: 'pluto-source-data',
          type: 'fill',
          layout: {
            'fill-sort-key': 0
          },
          paint: {
            'fill-color': [
              'case',
              ['boolean', ['feature-state', 'hover'], false],
              '#a3d',
              '#3ad'
            ],
            'fill-opacity': 0.6,
            'fill-outline-color': '#f1f1f1'
          }
        })

        this.createMapTooltip('pluto-lots')
      }
    },
    plutoLotSubscriber: function () {
      this.$store.subscribe((mutation, state) => {
        switch (mutation.type) {
          case 'setPlutoLots':
            this.addPlutoLots(this.plutoLots)

            this.queryMapByPoint()
            break
        }
      })
    },
    testHighlightLotSource () {
      if (typeof map.getLayer('selectedPlutoLotID') !== 'undefined') {
        map.removeLayer('selectedPlutoLotID')
        map.removeSource('selectedPlutoLot')
      }
    },
    checkMarker () {
      if (marker !== null) {
        marker.remove()
        marker = null
      }
    },
    queryMapByPoint () {
      marker = new this.mapbox.Marker()
        .setLngLat(this.selectedLngLat)
        .addTo(map)
    },
    highlightLot (e) {
      this.checkMarker()

      const clickedFeature = map.queryRenderedFeatures(e.point, {
        layers: ['pluto-lots']
      })[0]

      this.testHighlightLotSource()

      map.addSource('selectedPlutoLot', {
        type: 'geojson',
        data: clickedFeature.toJSON()
      })

      map.addLayer({
        id: 'selectedPlutoLotID',
        type: 'fill',
        source: 'selectedPlutoLot',
        layout: {
          'fill-sort-key': 100
        },
        paint: {
          'fill-color': '#a3d',
          'fill-opacity': 0.6
        }
      })

      return clickedFeature.geometry.coordinates[0]
    },
    createMapTooltip (mapLayer) {
      map.on('click', mapLayer, (e) => {
        e.originalEvent.cancelBubble = true

        let highlightedGeometry = this.highlightLot(e)

        featureID = e.features[0].id

        map.setFeatureState(
          { source: 'pluto-source-data', id: featureID },
          { hover: true }
        )
        const prop = e.features[0]['properties']

        // this.zoningData = createPlutoZoningObject(prop)
        this.$store.commit('setZoningData', createPlutoZoningObject(prop, highlightedGeometry))
      })

      map.on('mousemove', mapLayer, (e) => {
        map.getCanvas().style.cursor = 'pointer'

        if (e.features.length > 0) {
          const address = e.features[0]['properties']['Address']

          const coords = [e.lngLat.lng, e.lngLat.lat]

          popup.setLngLat(coords).setHTML(address).addTo(map)

          if (featureID !== null) {
            map.setFeatureState(
              { source: 'pluto-source-data', id: featureID },
              { hover: false }
            )
          }

          featureID = e.features[0].id

          map.setFeatureState(
            { source: 'pluto-source-data', id: featureID },
            { hover: true }
          )
        }
      })

      map.on('mouseleave', mapLayer, (e) => {
        map.getCanvas().style.cursor = ''

        if (featureID !== null) {
          map.setFeatureState(
            { source: 'pluto-source-data', id: featureID },
            { hover: false }
          )
        }
        popup.remove()
        featureID = null
      })
    }
  }
}
</script>

<style lang="scss">
$map-width: calc(100vw - #{$sidebar-width});

#Map {
  width: $map-width;
  height: 100vh;
}
.mapboxgl-canvas {
  left: 0;
}
#map-container {
  position: absolute;
  height: 100vh;
  width: $map-width;
  left: $sidebar-width;
  top: 0;

  overflow: hidden;
}
</style>
