/* global XLSX */

import { saveAs } from 'file-saver'
import { lineString } from '@turf/helpers'

import booleanCrosses from '@turf/boolean-crosses'

import inclusionaryHousingAreas from '@/assets/data/inclusionary_housing_areas.json'

import mandatoryInclusionaryHousingAreas from '@/assets/data/mandatory_inclusionary_housing_areas.json'

let polygonCheck = {
  'mandatory': mandatoryInclusionaryHousingAreas,
  'inclusionary': inclusionaryHousingAreas
}
export function createPlutoZoningObject (data, geo) {
  const format = require('d3-format').format
  let commaFormat = format(',')

  const boroughNameDict = {
    'BK': 'Brooklyn',
    'BX': 'Bronx',
    'MN': 'Manhattan',
    'QN': 'Queens',
    'SI': 'Staten Island'
  }

  // const landUseDict = {
  //   '01': 'One & Two Family Buildings',
  //   '02': 'Multi-Family Buildings',
  //   '03': 'Multi-Family Elevator Buildings',
  //   '04': 'Mixed Residential & Commercial Buildings',
  //   '05': 'Commercial & Office Buildings',
  //   '06': 'Public Facilities & Transporation Utility',
  //   '07': 'Transportation & Utility',
  //   '08': 'Public Facilities & Institutions',
  //   '09': 'Open Space & Outdoor Recreation',
  //   '10': 'Parking Facilities'
  // }

  const zoningDataObjectSchema = {
    address: data['Address'],
    blockInfo: [
      {
        title: 'BBL: ',
        value: data['BBL'],
        spacer: '|',
        exportLabel: 'BBL'
      },
      {
        title: 'Borough: ',
        value: boroughNameDict[data['Borough']],
        spacer: ' | ',
        exportLabel: 'Borough'
      },
      {
        title: 'Block: ',
        value: data['Block'],
        spacer: ' | ',
        exportLabel: 'Block'
      },
      {
        title: 'Lot: ',
        value: data['Lot'],
        spacer: '',
        exportLabel: 'Lot'
      }
    ],
    taxMapURL: `http://maps.nyc.gov/taxmap/map.htm?searchType=BblSearch&featureTypeName=EVERY_BBL&featureName=${data['BBL']}`,
    zoningMapURL: `https://www1.nyc.gov/assets/planning/download/pdf/zoning/zoning-maps/map${data['ZoneMap']}.pdf`,
    zoningDistricts: [],
    specialDistricts: [],
    geometry: geo,
    plutoData: {
      'lotArea': data['LotArea'],
      'lotDepth': data['LotDepth'],
      'zoningMap': data['ZoneMap'],
      'lot': data['Lot'],
      'lotFront': data['LotFront']
    },
    lotInfo: [{
      'key': 'LotArea',
      'format': commaFormat,
      'units': 'ft',
      'exportLabel': 'Lot Area'
    },
    {
      'key': 'LotDepth',
      'format': commaFormat,
      'units': 'ft',
      'exportLabel': 'Lot Depth'
    },
    {
      'key': 'BldgArea',
      'format': commaFormat,
      'units': 'ft',
      'exportLabel': 'Building Area'
    },
    {
      'key': 'BuiltFAR',
      'format': null,
      'units': '',
      'exportLabel': 'Built FAR'
    }
    ]
  }

  /**
   * ZONING DISTRICTS
   */
  const zoningDistrictKey = 'ZoneDist'
  const maxNumberOfZones = 4

  for (let i = 1; i < maxNumberOfZones; i++) {
    const key = zoningDistrictKey + i
    if (data.hasOwnProperty(key) && data[key] !== null && data[key] !== 'null') {
      zoningDataObjectSchema.zoningDistricts.push(data[key])
    }
  }
  /**
   * LOT INFORMATION
   */
  zoningDataObjectSchema.lotInfo = zoningDataObjectSchema.lotInfo.map((d) => {
    let value = (d['format'] === null ? data[d['key']] : d['format'](data[d['key']])) + ' ' + d['units']

    return {
      'key': d['exportLabel'],
      'value': value
    }
  })

  return zoningDataObjectSchema
}

export function downloadCSV (data, fileName) {
  let csvRows = data.map((d, i) => d.join(',').replace(' ', '%20'))

  let csvString = csvRows.join('%0A')

  let a = document.createElement('a')
  a.href = 'data:attachment/csv,' + csvString
  a.target = '_blank'
  a.download = `${fileName}.csv`

  document.body.appendChild(a)
  a.click()
}
export function downloadExcel (data, fileName) {
  class Workbook {
    constructor () {
      this.SheetNames = []
      this.Sheets = {}
    }
  }

  function styleCase (cell) {
    let bold = ['Zoning Calculations', 'Property Information', 'Address', 'Borough', 'Block', 'Lot', 'BBL', 'Zoning Map', 'Zoning District', 'Lot Area', 'Lot Depth', 'Lot Frontage', 'Total Lot Area', 'Total Lot Area (multiple zones)', 'ZR Sections', 'Item', 'Permitted or Required', 'Proposed', 'Compliance', 'link', 'General Description', 'Base FAR', 'Max FAR', 'Max Mechanical', 'Rear Yard', 'Sky Exposure', 'Tower', 'Zoning Notes']

    let fontSize = ['Zoning Calculations']

    function getBoldStyle (c) {
      c['s']['font'] = { 'bold': true }

      if (fontSize.includes(cell.v)) {
        c['s']['font']['sz'] = '14'
      }
    }

    let borderBottom = ['Zoning Calculations', 'ZR Sections', 'Item', 'Permitted or Required', 'Proposed', 'Compliance']

    function getBorderBottom (c) {
      c['s']['border'] = {
        'bottom': {
          'style': 'medium',
          'color': { rgb: 'FF000000' }
        }
      }
    }

    cell['s'] = {}

    if (bold.includes(cell.v)) {
      getBoldStyle(cell)
    }

    if (borderBottom.includes(cell.v)) {
      getBorderBottom(cell)
    }

    return cell
  }

  let wscols = []

  function setColumnWidth () {
    wscols.push({
      'wch': 20
    })
  }

  function createWorksheet (data, opts) {
    let ws = {}
    let range = { s: { c: 10000000, r: 10000000 }, e: { c: 0, r: 0 } }

    for (let R = 0; R !== data.length; ++R) {
      for (let C = 0; C !== data[R].length; ++C) {
        if (range.s.r > R) range.s.r = R
        if (range.s.c > C) range.s.c = C
        if (range.e.r < R) range.e.r = R
        if (range.e.c < C) range.e.c = C

        let cell = { v: data[R][C] }

        if (cell.v == null) continue
        let cellRef = XLSX.utils.encode_cell({ c: C, r: R })

        // check value number
        if (typeof cell.v === 'number') cell.t = 'n'
        // check value boolean
        else if (typeof cell.v === 'boolean') cell.t = 'b'
        // check value date
        else if (typeof (cell.v) === 'string' && (cell.v.includes('https') || cell.v.includes('http'))) {
          cell['l'] = {
            Target: cell.v,
            tooltip: 'link to zoning text'
          }

          // cell.v = 'link'
          cell['f'] = `=HYPERLINK("${cell.v}","link")`
        } else cell.t = 's'

        cell = styleCase(cell)

        ws[cellRef] = cell
        setColumnWidth()
      }
    }

    if (range.s.c < 10000000) ws['!ref'] = XLSX.utils.encode_range(range)

    ws['!cols'] = wscols

    return ws
  }
  /* function for downloading the excel file */
  function s2ab (s) {
    let buf = new ArrayBuffer(s.length)
    let view = new Uint8Array(buf)
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF
    return buf
  }

  function save (cellData) {
    let wsName = 'SheetJS'

    let wb = new Workbook(); let ws = createWorksheet(cellData)

    /* add worksheet to workbook */
    wb.SheetNames.push(wsName)
    wb.Sheets[wsName] = ws
    let wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'binary' })
    saveAs(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }), `${fileName}.xlsx`)
  }

  save(data)
}

export function checkPolygonCross (arrayToCheck, type) {
  let features = polygonCheck[type].features

  let checkMe = lineString(arrayToCheck)

  let check = false
  features.forEach((d, i) => {
    let geo = lineString(d['geometry']['coordinates'][0][0])

    let cross = booleanCrosses(checkMe, geo)

    if (cross) {
      console.debug(`inside ${type}`)
      check = cross
    }
  })

  return check
}
