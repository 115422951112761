<template>
  <div id="R10Input" ref="R10Input">

    <div ref="R10ResultTable" >
      <b-table id="R10ResultTable" striped hover :items="tableItems"></b-table>

      <b-btn v-if="tableItems.length > 0" variant="outline-success" @click="downloadExcel">Download Excel</b-btn>
    </div>

    <b-form v-if="show">
      <b-form-group
        id="input-1"
        label="Is the proposed project a Quality Housing Building?"
        description="see XXX for definition of Quality Housing Building"
        required
        class="mt-4"
      >
        <b-form-radio
          v-model="form.qualityHousingBldg"
          name="input-1-true"
          :value="true"
          >true</b-form-radio
        >
        <b-form-radio
          v-model="form.qualityHousingBldg"
          name="input-1-false"
          :value="false"
          >false</b-form-radio
        >
      </b-form-group>

      <b-form-group
        id="input-2"
        label="Is the proposed project expanding an existing building?"
        description=""
        required
        class="mt-4"
      >
        <b-form-radio
          v-model="form.expand"
          name="input-2-true"
          :value="true"
          >true</b-form-radio
        >
        <b-form-radio
          v-model="form.expand"
          name="input-2-false"
          :value="false"
          >false</b-form-radio
        >
      </b-form-group>

      <b-form-group
        id="input-3"
        label="Is the total floor area of proposed project over 25% residential?"
        description=""
        required
        class="mt-4"
      >
        <b-form-radio
          v-model="form.percentResi"
          name="input-3-true"
          :value="true"
          >true</b-form-radio
        >
        <b-form-radio
          v-model="form.percentResi"
          name="input-3-false"
          :value="false"
          >false</b-form-radio
        >
      </b-form-group>

      <b-form-group
        id="input-4"
        label="What is the lot type?"
        description="see lot type diagrams for additional information"
        required
        class="mt-4"
      >
        <b-input-group>
          <b-input-group-prepend>
            <!-- <b-form-checkbox switch class="mr-n2" v-model="form.lotType.showDiagram">
            </b-form-checkbox> -->
            <b-button variant="info" @click="form.lotType.showDiagram = !form.lotType.showDiagram">
              <i class="far fa-question-circle"></i>
            </b-button>
          </b-input-group-prepend>
          <b-form-select
            v-model="form.lotType.selected"
            :options="form.lotType.options"
            class=""
          ></b-form-select>
        </b-input-group>
      </b-form-group>

      <div class="form-image-container" v-if="form.lotType.showDiagram === true">
        <img height="auto" width="100%" src="../assets/images/lot_type_diagram.png" alt="lot type diagram" />
      </div>

      <!-- <b-form-group
        id="input-5"
        label="What is the width of the zoning lot?"
        description="Lot width usually runs parallel to street"
        required
        class="mt-4"
      >
        <b-input-group append="ft">
          <b-form-input
            min="0"
            v-model="form.lotWidth"
            id="input-5-number"
            type="number"
          ></b-form-input>
        </b-input-group>

      </b-form-group> -->

      <b-form-group
        id="input-6"
        label="Does the lot front a Wide Street?"
        description="In New York City, wide streets are 80-100+ feet wide. In Manhattan, almost all major avenues are wide streets."
        required
        class="mt-4"
      >
        <b-form-radio
          v-model="form.streetType"
          name="input-6-true"
          :value="true"
          >true</b-form-radio
        >
        <b-form-radio
          v-model="form.streetType"
          name="input-6-false"
          :value="false"
          >false</b-form-radio
        >
      </b-form-group>

      <b-form-group
        id="input-7"
        label="Is the lot on the Short side of the block?"
        description="see block type diagrams for additional information."
        required
        class="mt-4"
      >
        <b-form-radio v-model="form.blockType" name="input-7-true" :value="true"
          >true</b-form-radio
        >
        <b-form-radio
          v-model="form.blockType"
          name="input-7-false"
          :value="false"
          >false</b-form-radio
        >
      </b-form-group>

      <b-form-group
        id="input-9"
        label="Is the lot within 100ft of a park?"
        description="see part proximity diagram"
        required
        class="mt-4"
      >
        <b-form-radio v-model="form.parkProx" name="input-9-true" :value="true"
          >true</b-form-radio
        >
        <b-form-radio
          v-model="form.parkProx"
          name="input-9-false"
          :value="false"
          >false</b-form-radio
        >
      </b-form-group>

      <b-form-group
        id="input-8"
        label="If the site does not front a wide street, how far, in feet, is the site from a wide street?"
        description="see diagram for additional information."
        required
        class="mt-4"
      >

        <b-input-group append="ft">
          <b-form-input
            min="0"
            v-model="form.distWide"
            id="input-8-number"
            type="number"
          ></b-form-input>
        </b-input-group>

      </b-form-group>
<!--
      <b-form-group
        id="input-10"
        label="Is the lot in an Inclusionary Housing Zone?"
        description="description of inclusionary housing zone"
        required
        class="mt-4"
      >
        <b-form-radio
          v-model="form.incHousingZone"
          name="input-10-true"
          :value="true"
          >true</b-form-radio
        >
        <b-form-radio
          v-model="form.incHousingZone"
          name="input-10-false"
          :value="false"
          >false</b-form-radio
        >
      </b-form-group> -->

      <b-button type="submit" variant="primary" @click="onSubmit($event)"
        >Submit</b-button
      >
      <b-button
        type="reset"
        class="ml-4"
        variant="outline-danger"
        @click="onReset($event)"
        >Reset</b-button
      >
    </b-form>
  </div>
</template>

<script>

import { downloadExcel, checkPolygonCross } from '@/assets/library/helpers.js'
import { mapGetters } from 'vuex'

let params = {}

export default {
  name: 'R10Input',
  props: {
    plutoData: Object
  },
  data () {
    return {
      show: true,
      form: {
        qualityHousingBldg: false,
        expand: false,
        percentResi: false,
        streetType: false,
        blockType: false,
        distWide: 0,
        // lotWidth: 0,
        parkProx: false,
        // incHousingZone: false,
        letterSuffix: false,
        lotType: {
          showDiagram: false,
          // selected: 'Interior',
          selected: null,
          options: [
            { text: 'Select a lot type', value: null },
            { text: 'Interior', value: 'Interior' },
            { text: 'Through', value: 'Through' },
            { text: 'Corner', value: 'Corner' }
            // { text: 'Combination', value: 'Combination' }
          ]
        }
      },
      tableItems: []
    }
  },
  computed: {
    ...mapGetters({
      VERSION: 'getVersion',
      zoningData: 'getZoningData'
    })
  },
  methods: {
    downloadExcel () {
      console.debug(params)
      console.debug(this.zoningData)

      let d = this.zoningData

      let BBLDict = {}
      d.blockInfo.forEach((d) => {
        BBLDict[d.exportLabel] = d.value
      })

      /**
       * @param {Array} arr
       * @param {Number} maxLength
       */
      function fillEmpty (arr, maxLength) {
        let l = maxLength - arr.length
        if (l > 0) {
          for (let i = 0; i < l; i++) {
            arr.push('')
          }
        }
        return arr
      }

      let ZRSections = []
      Object.keys(d['R10']).forEach((key) => {
        let obj = d['R10'][key]

        if (obj.title !== 'Summary') {
          obj.categories.forEach((j, i) => {
            ZRSections.push([
              j.link.split('https://zr.planning.nyc.gov/')[1].split('#')[1],
              i === 0 ? obj.title : '',
              '',
              '',
              '',
              j.link,
              j.description
            ])
          })
        }
      })

      let answers = []
      this.tableItems.forEach((d) => {
        answers.push([d.item, d.value, d.note])
      })

      let csv = [
        ['Zoning Calculations'],
        ['Property Information', '', '', '', '', 'Zoning Notes'],
        ['Address', d.address, '', '', '', 'Lot Depth', d.plutoData.lotDepth],
        ['Borough', BBLDict['Borough'], '', '', '', 'Lot Frontage', d.plutoData.lotFront],
        ['Block', BBLDict['Block'], '', '', '', answers[0][0], answers[0][1]],
        ['Lot', BBLDict['Lot'], '', '', '', answers[1][0], answers[1][1]],
        ['BBL', BBLDict['BBL'], '', '', '', answers[5][0], answers[5][1]],
        ['Zoning Map', d.plutoData.zoningMap, '', '', '', answers[2][0], answers[2][1]],
        ['Zoning District', d.zoningDistricts.join('/'), '', '', '', answers[3][0], answers[3][1]],
        ['Lot Area', d.plutoData.lotArea, '', '', '', answers[4][0], answers[4][1]],
        [''],
        ['ZR Sections', 'Item', 'Permitted or Required', 'Proposed', 'Compliance', 'link', 'General Description'],
        ['']
      ]

      csv = csv.concat(ZRSections)

      csv.forEach((d) => fillEmpty(d, 7))

      downloadExcel(csv, d.address)
    },
    onSubmit (e) {
      e.preventDefault()
      e.stopPropagation()

      this.tableItems = []

      params = Object.assign({}, this.form)

      params.lotType = this.form.lotType.selected
      params.lotDepth = this.plutoData.lotDepth
      params.lotArea = this.plutoData.lotArea
      params.distWide = parseInt(params.distWide)
      // params.lotWidth = parseInt(params.lotWidth)
      params.lotWidth = this.plutoData.lotFront
      params.mandatoryIncHousingZone = checkPolygonCross(this.zoningData.geometry, 'mandatory')
      params.incHousingZone = checkPolygonCross(this.zoningData.geometry, 'inclusionary')

      this.$store.dispatch('readDecisionTree', params).then((result) => {
        Object.entries(result).forEach(([key, value], k) => {
          let v = Array.isArray(value) === true ? value[0] : value
          let note = Array.isArray(value) === true ? value[1] : ''

          this.tableItems.push({
            'item': key,
            'value': v,
            'note': note
          })
        })

        /**
         * scroll to table
         */
        let container = document.getElementById('Sidebar')
        let el = this.$refs['R10ResultTable']
        container.scrollTop = el.scrollHeight
      })
    },
    onReset (e) {
      e.preventDefault()

      // Reset our form values
      this.form.qualityHousingBldg = false
      this.form.expand = false
      this.form.percentResi = false
      this.form.streetType = false
      this.form.blockType = false
      this.form.distWide = 0
      // this.form.lotWidth = 0
      this.letterSuffix = false
      this.parkProx = false
      // this.incHousingZone = false
      this.tableItems = []
      // Trick to resetclear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    }
  }
}
</script>
