<template>
  <div id="Sidebar" class="text-left p-2" :class="{'expand': expandSidebar === true}">
    <div id="toggleExapnd" :class="{'expand': expandSidebar === true}" class="float-right z-index-high" @click='expandSidebar = !expandSidebar'>
      <i class="fas fa-compress-alt float-right" v-if="expandSidebar === true"></i>
      <i class="fas fa-expand-alt float-right"  v-if="expandSidebar === false"></i>
    </div>
    <div class="container-fluid">
      <div id="searchBox">
        <div class="row">
          <div class="col-12 p-2 text-left">
            <div class="d-flex flex-column">
              <h4>Zoining Information on Demand</h4>
              <div class="description-text">New York City | v.{{ VERSION }}</div>
            </div>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-12 d-flex flex-column">
            <h5 class="inline-text mr-4">Enter Address</h5>
            <!-- <small>The tool will query tax lots adjacent to the specified address.</small> -->
            <vue-google-autocomplete
              id="map"
              classname="form-control"
              placeholder="Start typing"
              v-on:placechanged="getAddressData"
            >
            </vue-google-autocomplete>
          </div>
          <!-- <div class="col-4 align-self-end">
            <b-input-group prepend="radius" append="ft" class="">
              <b-form-input id="searchRadius" type="number"  v-model="searchRadius" min="10" max="500" step="5" ></b-form-input>
            </b-input-group>
          </div> -->
        </div>
        <hr />
      </div>
      <div id="taxLotInfo" v-if="Object.keys(zoningData).length > 1">
        <div class="row mt-4">
          <div class="col-12">
            <h6>{{ address }}</h6>
            <div class="d-flex justify-content-start">
              <div v-for="(b, i) in blockInfo" :key="i">
                <div class="sublabel" :class="{ 'ml-2': i > 0 }">
                  {{ b.title }} {{ b.value }} {{ b.spacer }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-2 zoning-info-box">

          <div class="col-6">
            <div
              class="row"
              v-for="(data, index) in lotInfoCol1"
              :key="'col-1-' + index"
            >
              <div class="col-6">
                <div class="sublabel">{{ data.key }}</div>
              </div>
              <div class="col-6">
                <div class="sublabel">{{ data.value }}</div>
              </div>
            </div>
          </div>

          <div class="col-6">
            <div
              class="row"
              v-for="(data, index) in lotInfoCol2"
              :key="'col-2-' + index"
            >
              <div class="col-6">
                <div class="sublabel">{{ data.key }}</div>
              </div>
              <div class="col-6">
                <div class="sublabel">{{ data.value }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12 d-flex">
            <div class="d-flex">
              <h6>Tax Map</h6>
              <a :href="taxMapURL" target="_blank" class="ml-2">
                <i class="fas fa-external-link-alt"></i>
              </a>
            </div>
            <div class="d-flex ml-2">
              <h6>Zoning Map</h6>
              <a :href="zoningMapURL" target="_blank" class="ml-2">
                <i class="fas fa-external-link-alt"></i>
              </a>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12">
            <h6>Zoning District(s):</h6>
          </div>
        </div>
        <div class="row">
          <div class="col-12 d-flex">
            <b-btn
              @click="selectDistrict(zone)"
              class="zoing-districts mr-2"
              variant="outline-info"
              v-for="(zone, i) in zoningDistricts"
              :key="i"
              >{{ zone }}</b-btn
            >
          </div>
        </div>
      </div>

      <hr />

      <b-form-group
        label="Select method"
        v-if="Object.keys(districtInformation).length > 1"
      >
        <b-form-radio-group
          v-model="decisionFormFlag"
          :options="formOptions"
          name="radio-inline"
        ></b-form-radio-group>
      </b-form-group>

      <decisionForm
        v-if="decisionFormFlag === true"
        :district="district"
        :plutoData="plutoData"
        class="border-top-grey"
      />

      <div
        id="districtInfo"
        class="districtForm pt-2 border-top-grey"
        v-if="decisionFormFlag === false"
      >
        <div class="row mt-2">
          <div class="col-12">
            <!-- <h4>{{district}}</h4> -->
            <!-- <h5 :class="databaseMessageToggle" >{{databaseMessage.message}}</h5> -->
            <b-alert
              :show="databaseMessage.showAlert"
              :variant="databaseMessageToggle"
              >{{ district }} {{ databaseMessage.message }}</b-alert
            >
          </div>
        </div>
        <div
          class="row mt-2"
          v-for="d in Object.keys(districtInformation)"
          :key="d.key"
        >
          <div class="col-12">
            <hr />
            <h4>{{ districtInformation[d].title }}</h4>
            <div
              class="ml-2 categories"
              v-for="j in districtInformation[d].categories"
              :key="'category' + j.header + Math.random()"
              :class="j.header"
            >
              <div class="d-flex mt-4">
                <h5>{{ j.header }}</h5>
                <a :href="j.link" target="_blank" rel="noopener noreferrer"
                  ><i class="fas fa-link ml-1" title="open link"></i
                ></a>
              </div>
              <p class="description-text">
                {{ j.description }}
              </p>
              <districtDiagram v-if="j.diagram !== null" :src="j.diagram" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import districtDiagram from '@/components/districtDiagram.vue'
import { mapGetters } from 'vuex'

import decisionForm from '@/components/decision_form.vue'

export default {
  name: 'Sidebar',
  watch: {
    zoningData: function (newVal, oldVal) {
      this.decisionFormFlag = false
      this.districtInformation = {}
    }
  },
  components: {
    decisionForm,
    VueGoogleAutocomplete,
    districtDiagram
  },
  data () {
    return {
      decisionFormFlag: false,
      searchRadius: 100,
      expandSidebar: false,
      districtInformation: {},
      district: '',
      flyToZoom: 17,
      databaseMessage: {
        status: false,
        message: '',
        showAlert: false
      },
      formOptions: [
        { text: 'summary', value: false },
        { text: 'questionaire', value: true }
      ]
    }
  },
  computed: {
    ...mapGetters({
      VERSION: 'getVersion',
      zoningData: 'getZoningData'
    }),
    plutoData () {
      return this.zoningData.plutoData
    },
    lotInfo () {
      return this.zoningData.lotInfo
    },
    lotInfoCol2 () {
      return this.lotInfo
        .slice(3, this.zoningData.lotInfo.length)
        .map((d) => d)
    },
    lotInfoCol1 () {
      return this.lotInfo.slice(0, 3).map((d) => d)
    },
    blockInfo () {
      return this.zoningData.blockInfo
    },
    zoningDistricts () {
      return this.zoningData.zoningDistricts
    },
    taxMapURL () {
      return this.zoningData.taxMapURL
    },
    zoningMapURL () {
      return this.zoningData.zoningMapURL
    },
    address () {
      return this.zoningData.address
    },
    databaseMessageToggle () {
      return this.databaseMessage.status === true ? 'success' : 'danger'
    }
  },
  methods: {
    updateDatabaseMessage (toggle, show) {
      this.databaseMessage.status = toggle
      this.databaseMessage.showAlert = show

      if (toggle) {
        this.databaseMessage.message = 'Data found'
      } else {
        this.databaseMessage.message = 'Data not found'
      }
    },
    getAddressData (address) {
      // flys to location through ease function
      this.$emit('updateFlyTo', [
        address.longitude,
        address.latitude,
        this.flyToZoom
      ])
      this.$store.dispatch('readPlutoData', [
        address.longitude,
        address.latitude,
        this.searchRadius
      ])
    },
    selectDistrict (zone) {
      this.$store.dispatch('readDistrictData', zone).then((res) => {
        this.district = zone

        if (res !== null) {
          this.updateDatabaseMessage(true, false)
          this.districtInformation = res

          this.$store.commit('setZoningDataDistrict', [zone, this.districtInformation])
        } else {
          this.updateDatabaseMessage(false, true)
          this.districtInformation = {}
        }
      })
    }
  }
}
</script>

<style lang="scss">

#Sidebar {
  position: absolute;
  top: 0;
  left: 0;
  width: $sidebar-width;

  transition: width .3s ease-in;

  &.expand{
    width: 80vw!important;
  }
  height: 100vh;

  background: white;

  overflow-y: auto;
  overflow-x: hidden;
}

.inline-text {
  white-space: nowrap;
}

#toggleExapnd{
  position: fixed;
  top: 1rem;
  left: calc(#{$sidebar-width} - 75px);
  transition: left .3s ease-in;
  padding: 1rem;

  background: white;
  cursor: pointer;

  &.expand{
    left: calc(80vw - 75px)!important;
  }

  &:hover{
    color: steelblue;
  }
}

#searchBox {
  max-height: 30vh;
  width: $max-width;
  max-width: $max-width;
}
#taxLotInfo {
  max-height: 40vh;
  width: $max-width;
  max-width: $max-width;
}
</style>
